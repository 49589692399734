body{
  font-size: 1rem !important;
}

.refreshButton {
  margin-left: 10px !important;
}

.colorPreviewSwatch {
  font-size: 20px;
  padding-left: 30px;
  border-radius: 5px;
}

.brand-preview-image{
  height: 25vh;
}

.dropdown-menu {
  position: fixed !important;
  top: auto !important;
  left: auto !important;
  overflow: auto !important;
}

.margin-top-timeline {
  margin-top: 0px;
}

.no-timeline-seperator-padding{
  padding: 0px !important;
}

.dash-insights-link{
  color: #656565;
}
.dash-insights-link:hover{
  color: #656565;
  text-decoration: none;
}

.card, .topnavbar, .aside-container, .offsidebar, .btn, .swal-modal, .swal-button, .messagebox-content .btn-group{
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.no-shadow {
  box-shadow: none !important;
}

.btn-group>.btn{
  box-shadow: none;
}

.card-title>a{
  float: right;
}

.right{
  float: right;
}

.left{
  float: left;
}

.content-wrapper div:not(.btn-group)>.btn, .content-wrapper .btn-toolbar>a, .btn-group { 
  margin: 0 4px 4px 0; 
}

//datatable overflow goes out of page. to prevent that
.dataTables_wrapper{
  overflow: auto;
}
.dataTables_wrapper>.row{
  margin-left: 0px;
  margin-right: 0px;
}
//
//contentheading to align button to the right
.content-heading > div:first-of-type{
  margin-right: auto !important;
}
.select-container{
  width: 400px;
}
//inbox
.chat {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 50vh;
}

.messages {
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  white-space: pre-wrap;
}

.message > img{
  max-width: 100% !important;
}

.them {
  align-items: flex-start;
}

.them .message {
  margin-right: 25%;
  background-color: #eee;
  position: relative;
}

.them .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}
.them .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.me {
  align-items: flex-end;
}

.me .message {
  color: white;
  margin-left: 25%;
  background: linear-gradient(to bottom, #76a8e8 0%, #5d9cec 100%);
  background-attachment: fixed;
  position: relative;
}
.me .message > p > a{
  color: white;
  text-decoration: underline;
  text-decoration-color: white;
}
.me .message > p > a:hover{
  color: #000000;
  text-decoration: underline;
  text-decoration-color: #000000;
}

.me .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom, #76a8e8 0%, #5d9cec 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.me .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}

/*
 * Scroll bar style
 */

::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.2);
  box-shadow: inset 0 0 1px rgba(0,0,0,0.2);
  border-radius: 10px;
  background-color: #F5F5F5;
}
::-webkit-scrollbar
{
  width: 8px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,.2);
  box-shadow: inset 0 0 2px rgba(0,0,0,.2);
  background-color: #d6d7d8;
}

.no-lr-padding{
  padding-right: 0px;
  padding-left: 0px;
}

.messagebox-heading{
  margin-top: 10px;
  text-align: center;
}
.messagebox-content{
  padding: 10px;
  overflow: auto;
  overflow-y: auto;
  word-break: break-all;
}
.messagebox-bottom{
  text-align: center;
}
.messagebox-content-full{
  max-height: calc(62vh + 60px);
  min-height: calc(62vh + 60px);
}

.messagebox-content-fourty{
  max-height: 40vh;
  min-height: 40vh;
}

.messagebox-content-timeline{
  max-height: calc(63vh + 110px); //timeline doesn't have button at bottom. small button is 47px height.
  min-height: calc(63vh + 110px);
}

.messagebox-content-twentyfive{
  height: 100px !important;
  position: relative;
  max-height: 23vh;
  min-height: 23vh;
  overflow-y: hidden;
  padding-bottom: 0px;
  font-size: inherit !important;
}

.chat-all-selected{
  // background-color: #0095ff;
  background-color: #5d9cec;
  border-radius: 10px;
  color: white;
}

.chat-all-single-block{
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.chat-all-single-block:not(.chat-all-selected):hover{
  background-color: gray;
  border-radius: 10px;
  color: white;
}
.chat-all-content-unread{
  background-color: #eeeeee;
  border-radius: 10px;
}

.message-source-icon{
  padding-top: 3px;
  padding-left: 3px;
}

.message-divider{
  border-top: 1px solid #ffffff;
  margin: 8px -15px;
}

.horizontal-line{
  border-bottom: 1px dashed #9f9a9a;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
.horizontal-line .badge{
  display: inline !important;
  line-height: 0 !important;
}

.typemessage{
  width: 100% !important;
  resize: none !important;
  height: 100% !important;
  height: -moz-available !important;          /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available !important;  /* Mozilla-based browsers will ignore this. */
  height: stretch !important;
  border-radius: 8px !important;
  border: 1px solid #dde6e9 !important;
}

.message-preview-variable{
 color: #E9B46F;
}

.message-preview{
  text-overflow: ellipsis; 
  overflow: hidden; 
  white-space: nowrap;
}

#chatsearchbutton{
  border-color: #5d9cec;
  box-shadow: none !important;
}

#messagelength{
  z-index: 1;
  position: absolute; 
  right: 10px; 
  bottom: 4px; 
  padding-right: 12px;
}

//inbox End

.preview-img{
  max-width: 100%;
  max-height: 80vh;
}

.hidden{
  visibility: hidden;
}

// For preview of form

.mobile-frame {
  background: url(/img/samsung.png);
  background-repeat: no-repeat;
  background-size: 272px 543px;
  height: 543px;
  width: 272px;
  overflow: hidden;
  position: absolute;
}

.mobile-frame2 {
  background: url(/img/iphonex.png);
  background-repeat: no-repeat;
  background-size: 272px 543px;
  height: 543px;
  width: 272px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}
.mobile-screen2{
  position: relative;
  top: 19px;
  left: 8px;
  width: calc(272px - 16px);
  height: calc(543px - 35px);
  overflow: hidden;
  border-radius: 24px;
}
.mobile-screen-content-container{
  position: relative;
  top: 10px;
  left: 21px;
  width: 230px;
  height: 443px;
  overflow: hidden;
  border-radius: 0px 0px 24px 24px;
  margin-bottom: 40px;
}
.mobile-screen-content2{
  height: inherit;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}
.mobile-screen-topnavbar-container{
  position: relative;
  top: 18px;
  left: 8px;
  width: 256px;
  height: 70px;
  overflow: hidden;
  border-radius: 24px;
  z-index: 0;
}
.mobile-screen-topnavbar2{
  min-height: 70px;
  margin: -10px;
}

.mobile-screen-topnavbar2>img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  max-height: 70px;
}

.mobile-screen-content2-sticky-button{
  position: sticky;
  top: 92%;
  width: 100%;
}

.mobile-screen-content2-sticky-button2{
  position: sticky;
  top: 80%;
  width: 100%;
}

.mobile-screen-content2-sticky-button3{
  position: sticky;
  width: 100%;
}

// .mobile-frame>.mobile-screen{
//   // z-index: -1 !important;
// }
// .mobile-screen {
//   position: relative;
//   top: 19px;
//   left: 17px;
//   width: calc(272px - 36px);
//   height: calc(543px - 38px);
//   border: none;
//   overflow-y: auto;
// }
.mobile-screen{
  position: relative;
  top: 31px;
  left: 8px;
  width: calc(272px - 16px);
  height: calc(543px - 55px);
  overflow: hidden;
  border-radius: 24px;
}
.mobile-screen-content{
  height: inherit;
  overflow-y: auto;
  padding: 10px;
}
.mobile-screen-topnavbar{
  min-height: 55px;
  margin: -10px;
}
.mobile-screen-topnavbar>img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  max-height: 55px;
}

.mobile-screen-content>button{
  position: sticky;
  top: 92%;
  left: 35%;
}
// form preview end

//overriding wrapper property for form preview above to scroll along with form. position: sticky doesn't work if any ancestor of element has a overflow property hidden
// .wrapper{
//   overflow-x: inherit !important;
// }
// .wrapper{
//   display:table;
// }

// form render
.fb-render{
  padding: 10px;
}

.cb-wrap{
  position: sticky !important;
  top: 100px !important;
}
//form render end

.swal-button--cancel::before {
  font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f057\0020";
}
.swal-button.red-button::before {
  font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f2ed\0020";
}
.swal-button--confirm::before{
  font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f058\0020";
}

.red-button{
  background-color: #f05050 !important;
  border-color: #f05050 !important;
}
.red-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 106, 106, 0.5);
}
.red-button:hover {
  background-color: #ed2d2d;
  border-color: #ec2121;
}
// ---------------------Authenticator---------------
// .sign-in, .sign-up {
//  text-align: center;
// }

:root{
  --amplify-primary-color: #1797be;
  --amplify-primary-tint: #0d6985; 
  --amplify-primary-shade: #052f3b;
  --text-align:center;
  }
.hydrated>:not(:last-child){
  text-align: var(--text-align);
}


//---------------------------------------------------
.input-group.with-focus .form-control.is-invalid + .input-group-append > .input-group-text {
  border-color: #f05050 !important;
}

.input-group.with-focus .form-control:focus + .input-group-append > .input-group-text {
  border-color: #66afe9;
}

.logOut-overlay{
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgba(58, 57, 57, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
}
.logOut-overlay-content {
  position: relative;
  top: 30%; /* 25% from the top */
  margin-left: 60px;
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
  color: white;
}

.Toastify__toast-container--top-right, .Toastify__toast-container--top-left, .Toastify__toast-container--top-center {
  top: 70px !important;
}
.Toastify__toast{
  border-radius: 0.25rem !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23) !important;
}

//formbuilder
.control-icon>[class^="icon-"]{
  margin-left: -3px;
}
.form-builder-dialog.data-dialog{
  overflow-y: scroll;
}
.formBuilderPreview{
  @media (min-width: 992px){
    position: inherit;
  }
}

.formBuilderButtonToolbar{
  width: 26%;
}
//formbuilder ends

//phone demo preview
.mobile-preview-logo>img{
  max-width: 50%;
}
.text-center>p{
  white-space: pre-wrap;
  font-size: 0.8rem !important;
}
.pincode-input-container >.pincode-input-text{
  width: 35px !important;
  height: 35px !important
}
//

//form builder preview only visible when activeTab is form
.fb-render>.rendered-form{
  display: block !important;
}

.block-center>.rendered-form{
  display: none !important;
}
//

//file upload box
.fileuploader{
  min-height: 100px;
  border-radius: 5px;
  border: 2px dashed rgba(83, 77, 77, 1);
  margin-left: auto;
  margin-right: auto;
}

.uploadToastFont{
  font-size: 1rem;
}
//

//Loader color for white background
.loaderDisplayInline{
  display: inline-block;
}
.loaderColor>div>div{
  background-color: #cacaca;
}
//

//datatable expandable row
.rdt_ExpanderRow{
  background-color: #f6f6f6 !important;
}
.progress-bar-full-width{
  width: 100%;
}

//update form page
:root { 
  --updatenav-min-height: 80px !important;
}

.updatenav{
  box-shadow: none !important;
  min-height: var(--updatenav-min-height);
}

.updatenav>img, .updatenav>a{
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: calc(var(--updatenav-min-height) - 20px);
}

.updatenav>button{
  background: none;
  border: none;
  box-shadow: none;
  margin-left: auto;
  color: var(--update-brand-font-color);
}

.updatenav>button:hover, .updatenav>button:focus{
  background: none;
  border: none;
  box-shadow: none;
  color: var(--update-brand-font-color);
}

.updateContainer{
  margin-left: 0px !important;
  margin-top: 0px !important;
  --update-brand-background-color: #1797be;  // default background color
  --update-brand-font-color: #fff;  //default font color
}

.updateContainer>.content-wrapper{
  border-top: none !important;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.updateButtonContainer>.btn, .updateButtonContainer>a>.btn{
  max-width: 400px;
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  display: block;
}

.updateButtonSecond{
  margin-top: 10px !important;
}

.updateFooter>.footer-container{
  margin-left: 0px !important;
  padding: 5px 20px 5px 20px;
}
.updateFooter>.footer-container>a>img{
  height: 45px
}
.updatePreviewLogo>img{
  max-height: 25vh;
  min-height: 25vh;
}

.update-fb-render{
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.update-fb-render>.rendered-form>div>header, .update-fb-render>.rendered-form>div>.formbuilder-select-label, .update-fb-render>.rendered-form>div>.formbuilder-checkbox-group-label, .update-fb-render>.rendered-form>div>.formbuilder-radio-group-label{
  font-weight: bold;
}

.bold{
  font-weight: 600;
}

.updateGrayColor{
  color: #808080;
}

.update-fb-render>.rendered-form>div>.formbuilder-select-label + select:focus{
  border-color: var(--update-brand-background-color) !important;
}

.update-fb-render>.rendered-form>div>.formbuilder-select-label + select{
  border:none !important;
  border-bottom:1px solid #000000 !important;
  background: none;
  border-radius: 0 !important;
}
//

//

.float-label, .form-padding{
  padding-bottom: 15px !important;
  position: relative;
  box-shadow: none !important;
}

.float-label input ~ label, .float-label textarea ~ label{
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:10px;
  top:5px;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
  color: #9a9a9a;
  font-weight: bold;
}

.float-label input, .float-label textarea{
  border:none !important;
  border-bottom:1px solid #000000 !important;
  background: none;
  border-radius: 0 !important;
}

.float-label input, .float-label textarea, .form-padding select{
  font-weight: bold;
}

.float-label input:focus, .float-label textarea:focus{
  outline:none;
  border-bottom:2px solid var(--update-brand-background-color) !important; 
}

.float-label input:focus + label, .float-label input:not(:placeholder-shown) + label{
  top:-10px;
  font-size:14px !important;
  color: var(--update-brand-background-color);
}

.float-label input[id^='autocomplete-']:focus ~ label, .float-label input[id^='autocomplete-'] + input[value]:not([value=""]) ~ label{
  top:-10px;
  font-size:14px !important;
  color: var(--update-brand-background-color);
}

.float-label textarea:focus + label, .float-label textarea:not(:placeholder-shown) + label{
  top:-10px;
  font-size:14px !important;
  color: var(--update-brand-background-color);
}

.security-modal{
  min-width: 90vw !important;
  margin-top: 0px;
}

.security-modal>.modal-content{
  min-height: 70vh;
  background: var(--update-brand-background-color);
  color: var(--update-brand-font-color);
}
.security-modal>.modal-content>.modal-header{
  margin: auto;
  border: none;
}
.security-modal>.modal-content>.modal-header>.modal-title{
  font-weight: bold;
  font-size: 26px;
}
.security-modal>.modal-content>.modal-body{
  margin: auto;
}
.security-modal>.modal-content>.modal-footer{
  border: none;
}

.security-modal>.modal-content>.modal-footer>button{
  background-color: var(--update-brand-background-color);
  color: var(--update-brand-font-color);
  border: none;
  box-shadow: none;
}
//